import { colors } from '@config/theme'
import { api } from '@libs/api'
import { Alert, Checkbox, CircularProgress, Drawer, FormControlLabel, FormGroup, Snackbar } from '@mui/material'
import { useAppDispatch, useAppSelector } from '@store/hooks.ts'
import { getUserList } from '@store/userList/userListSlice.ts'
import { CloseButton } from '@stories/Icons/CloseButton/CloseButton.tsx'
import { Button } from '@stories/Theme/Button/Button'
import mixpanel from 'mixpanel-browser'
import { FC, ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'

import redis from '../../../libs/lockr.ts'
import { UserList } from '../../../libs/types/UserList'
import CreateNewListDialog from './CreateNewListDialog'
interface AddToListDrawerProps {
  visible: number
  onClose: () => void
}

const DrawerWrapper = styled(Drawer)`
  display: flex;
  flex-direction: column;
  .add-to-list-drawer-header {
    max-width: 352px;
    width: 100%;
    min-width: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 16px 10px;

    h3 {
      color: var(${colors.brandDefault});
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px; /* 155.556% */
    }
    .close-btn {
      cursor: pointer;
    }
  }
  hr {
    width: calc(100% - 20px);
    margin: 0px auto;
    border-bottom: 1px solid #d0d5dd;
    &.mt-auto {
      margin-top: auto;
    }
  }
  .add-to-list-drawer-body {
    padding: 1rem 1.25rem;
    min-height: 200px;
    overflow-y: auto;
  }
  .new-list-btn-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
  }
  .loading-container {
    position: absolute;
    left: calc(50% - 15px);
    top: calc(50% - 15px);
  }
`

export const AddToListDrawer: FC<AddToListDrawerProps> = ({ visible, onClose }) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const userFavoriteCamps = useAppSelector((state) => state.userCampList.userList)
  const isUserListLoading = useAppSelector((state) => state.userCampList.isLoading)
  const [errorMessage, setErrorMessage] = useState<ReactNode>(null)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (visible) {
      mixpanel.track('Camp Heart Button Clicked')
    }
  }, [visible])

  const addCampToUserList = async (favouriteListId: number) => {
    setLoading(true)

    try {
      await api.addCampUserList(favouriteListId, visible)
      redis.rm('api:userList')
      dispatch(getUserList())
      mixpanel.track('Add Camp to list')
    } catch (error: any) {
      // Show error message and log to Mixpanel after retries have been exhausted or time limit is reached
      setErrorMessage(
        <p>
          Sorry, we couldn&apos;t add the selected camp to your list. Please try again. If the problem persists, please
          contact us at <a href="mailto:email@camperoni.com">email@camperoni.com</a>
        </p>
      )
      mixpanel.track('Error', {
        'Error Type': 'Request Error',
        'Error Code': error?.response?.status,
        'Request Type': 'HTTP PATCH',
        'Error Name': 'Add camp to list failed',
      })
    }

    setLoading(false)
  }

  const removeCampFromUserList = async (favouriteListId: number) => {
    setLoading(true)

    try {
      await api.deleteCampUserList(favouriteListId, visible)
      redis.rm('api:userList')
      dispatch(getUserList())
      mixpanel.track('Removed Camp from list')
    } catch (error: any) {
      // Show error message and log to Mixpanel after retries have been exhausted or time limit is reached
      setErrorMessage(
        <p>
          Sorry, we couldn&apos;t remove the selected camp from your list. Please try again. If the problem persists,
          please contact us at <a href="mailto:email@camperoni.com">email@camperoni.com</a>
        </p>
      )
      mixpanel.track('Error', {
        'Error Type': 'Request Error',
        'Error Code': error?.response?.status,
        'Request Type': 'HTTP PATCH',
        'Error Name': 'Remove camp from list failed',
      })
    }

    setLoading(false)
  }

  const onHandleChange = async (value: boolean, favouriteListId: number) => {
    if (value) {
      await addCampToUserList(favouriteListId)
    } else {
      await removeCampFromUserList(favouriteListId)
    }
  }
  return (
    <>
      <DrawerWrapper anchor={'right'} open={!!visible} onClose={onClose}>
        <div className="add-to-list-drawer-header">
          <h3>Add camp to...</h3>
          <CloseButton onClick={onClose} />
        </div>
        <hr />
        <div className="add-to-list-drawer-body">
          <>
            {(isUserListLoading || loading) && (
              <div className="loading-container">
                <CircularProgress size={30} style={{ color: 'black' }} />
              </div>
            )}
            {userFavoriteCamps?.map((favouriteList: UserList) => (
              <FormGroup key={favouriteList.id}>
                <FormControlLabel
                  data-testid="drawer-list-item"
                  label={favouriteList.name}
                  control={
                    <Checkbox
                      onChange={(e) => onHandleChange(e.target.checked, favouriteList.id)}
                      checked={!!favouriteList.camps_decorated.find((camp) => camp.id === visible)}
                      disabled={isUserListLoading || loading}
                    />
                  }
                  disabled={isUserListLoading || loading}
                />
              </FormGroup>
            ))}
          </>
        </div>
        <hr className="mt-auto" />
        <div className="new-list-btn-wrapper">
          <Button
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                <path
                  d="M10.972 4.56122V16.2279M5.13867 10.3946H16.8053"
                  stroke="#323D77"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
            title={'Create a new list'}
            variant="secondary"
            onClick={() => setOpen(true)}
            disabled={loading}
          />
        </div>
      </DrawerWrapper>
      <CreateNewListDialog open={open} setOpen={setOpen} addVisibleItemToFavoritesList={onHandleChange} />
      <Snackbar
        data-testid="error-message"
        open={errorMessage !== null}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => setErrorMessage(null)}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
    </>
  )
}
