const on = (eventName: string, cb: (data: any | null) => void): void => {
  window.addEventListener(eventName, cb)
}

const off = (eventName: string, cb: (data: any | null) => void): void => {
  window.removeEventListener(eventName, cb)
}

const trigger = (eventName: string, data: any = {}, cb?: (args?: any) => void): void => {
  dispatchEvent(new CustomEvent(eventName, { detail: data }))
  if (cb) {
    setTimeout(cb, 0)
  }
}

const RESET_PAGING = 'RESET_PAGING'
const RESET_FILTERS = 'RESET_FILTERS'
const MIXPANEL_LIST_CLICKED = 'CTA List Clicked'
const MIXPANEL_FILTER_ACTION = 'Filter Action'
const MIXPANEL_FILTER_URL = 'Filter Action from URL'
const MIXPANEL_CTA_CLICKED = 'CTA Clicked'

const CALENDAR_CLEAR_FILTER = 'CALENDAR_CLEAR_FILTER'
const DISTANCE_CLEAR_FILTER = 'DISTANCE_CLEAR_FILTER'
export default {
  MIXPANEL_FILTER_ACTION,
  MIXPANEL_FILTER_URL,
  MIXPANEL_CTA_CLICKED,
  MIXPANEL_LIST_CLICKED,
  CALENDAR_CLEAR_FILTER,
  DISTANCE_CLEAR_FILTER,
  RESET_FILTERS,
  RESET_PAGING,
  off,
  on,
  trigger,
}
