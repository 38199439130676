import { api } from '@libs/api'
import Eventbus from '@libs/eventbus'
import { applyFilters, resetFilters } from '@store/campFilters/campFiltersSlice'
import { useAppDispatch } from '@store/hooks'
import { AuthMenu } from '@stories/Components/AuthMenu/AuthMenu'
import { Button } from '@stories/Theme/Button/Button'
import { Logo } from '@stories/Theme/Logo/Logo'
import classNames from 'classnames'
import { FC, useEffect } from 'react'
import { RxHeart } from 'react-icons/rx'
import { Link } from 'react-router-dom'

import { EllipseEnum } from '../../libs/types/Ellipse'
import { navListDataProps } from '../../libs/types/Nav'
import Ellipse from '../Ellipse'
import SideNav from './SideNav'
import { HeaderWrapper } from './style'

const navData = [
  {
    id: 'nav-item-1',
    title: 'Home',
    path: '/',
    active: false,
  },
  {
    id: 'nav-item-2',
    title: 'Camp Search',
    path: '/camps',
    active: false,
  },
  {
    id: 'nav-item-3',
    title: 'Memberships',
    path: '/blog?p=camperoni-membership-options',
    target: '_blank',
    active: false,
  },
  {
    id: 'nav-item-4',
    title: 'Merch shop',
    path: 'https://camperoni.etsy.com',
    target: '_blank',
    active: false,
  },
  {
    id: 'nav-item-5',
    title: 'Blog',
    path: '/blog',
    target: '_blank',
    active: false,
  },
]
const Header: FC<{ pageTitle?: string }> = ({ pageTitle }) => {
  const dispatch = useAppDispatch()
  let isLoggedIn = api.isUserLoggedIn()

  useEffect(() => {
    isLoggedIn = api.isUserLoggedIn()
  }, [isLoggedIn])

  const handleCampSearchClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (pageTitle === 'Camp Search') {
      event.preventDefault()
      handleResetFilters()
    }
  }

  const handleResetFilters = (): void => {
    dispatch(resetFilters())
    Eventbus.trigger(Eventbus.RESET_FILTERS)
    Eventbus.trigger(Eventbus.DISTANCE_CLEAR_FILTER)

    Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
      filter: 'Reset All Filters',
    })
    dispatch(applyFilters())
  }
  return (
    <>
      <HeaderWrapper>
        <div className="nav-container">
          <div className="left-part">
            <Logo />
            <ul className="nav-list" data-testid="nav-bar-container">
              {navData.map((navListItem: navListDataProps) => {
                const active = pageTitle === navListItem.title
                return (
                  <li key={navListItem.id}>
                    <Link
                      to={navListItem.path}
                      target={navListItem.target}
                      className={classNames({
                        active: active,
                      })}
                      onClick={navListItem.title === 'Camp Search' ? handleCampSearchClick : undefined}
                    >
                      {navListItem.title}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
          {isLoggedIn ? (
            <div className="right-part-desktop">
              <Link to={'/favorites'}>
                <Button
                  icon={<RxHeart fontSize="1rem" />}
                  size="small"
                  variant="secondary"
                  rounded={true}
                  mx="0.4rem"
                />
              </Link>
              <AuthMenu />
            </div>
          ) : (
            <div className="right-part-desktop">
              <Link to="/login">
                <Button title={'Login'} size="small" variant={'secondary'} mr={'0.75rem'} />
              </Link>
              <Link to="/register">
                <Button title={'Sign up'} size="small" variant={'primary'} />
              </Link>
            </div>
          )}

          <SideNav navListData={navData} pageTitle={pageTitle} />
        </div>
      </HeaderWrapper>
      <Ellipse type={EllipseEnum.default} />
    </>
  )
}

export default Header
