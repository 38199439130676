import Eventbus from '@libs/eventbus'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { Box, Checkbox, FormControlLabel, FormGroup, InputAdornment, TextField } from '@mui/material'
import { setProviders } from '@store/campFilters/campFiltersSlice'
import { useAppDispatch, useAppSelector } from '@store/hooks.ts'
import React, { useState } from 'react'

const ProviderFilter = () => {
  const dispatch = useAppDispatch()

  const availableProviders = useAppSelector((state) => state.campFilters.providerList)
  const selectedProviders = useAppSelector((state) => state.campFilters.filters.selectedProviders)

  const [providerSearch, setProviderSearch] = useState('')

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      dispatch(setProviders([...selectedProviders, event.target.value]))
      Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
        filter: 'Provider Name',
        value: event.target.value,
      })
    } else {
      const filteredProviders = selectedProviders.filter((p: string) => p !== event.target.value)
      dispatch(setProviders(filteredProviders))
    }
    Eventbus.trigger(Eventbus.RESET_PAGING)
  }

  return (
    <Box>
      <TextField
        inputProps={{
          'data-testid': 'provider-search',
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlinedIcon />
            </InputAdornment>
          ),
        }}
        placeholder="Search for provider"
        variant="outlined"
        size="small"
        fullWidth
        value={providerSearch}
        onChange={(e) => setProviderSearch(e.target.value)}
        sx={{
          mb: 2,
          '& .MuiInputBase-root': {
            borderRadius: '20px',
            py: 0.2,
          },
        }}
      />
      <Box
        maxHeight={160}
        className="custom-scrollbar"
        sx={{
          overflowY: 'auto',
        }}
      >
        <FormGroup data-testid="providers-checkbox-lists">
          {availableProviders
            .filter((provider) =>
              providerSearch ? provider.name.toLowerCase().includes(providerSearch.toLowerCase()) : provider
            )
            .map((provider) => (
              <FormControlLabel
                key={provider.id}
                control={
                  <Checkbox
                    checked={selectedProviders.includes(provider.id.toString())}
                    value={provider.id}
                    onChange={handleChange}
                    data-testid={`provider-${provider.name}`}
                  />
                }
                label={provider.name}
              />
            ))}
        </FormGroup>
      </Box>
    </Box>
  )
}

export default ProviderFilter
