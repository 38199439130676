import { colors } from '@config/theme'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import React from 'react'
import styled, { css } from 'styled-components'

interface HeartIconProps {
  className?: string
  onClick: () => void
  rounded?: boolean
  active?: boolean
}

const HeartIconWrapper = styled.button<HeartIconProps>`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;

  display: flex;
  gap: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid ${colors.brandGray300};
  background: ${colors.brandWhite};
  justify-content: center;
  cursor: pointer;
  padding: 0.5rem;
  width: 2.25rem;
  height: 2.25rem;

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }

  ${(props) =>
    props.active &&
    css`
      background: ${colors.brandBlue};
      color: ${colors.brandWhite};
      border: 1px solid transparent;
    `}

  ${(props) =>
    props.rounded &&
    css`
      border-radius: 2.25rem;
    `}
`

export const Favorite: React.FC<HeartIconProps> = ({ className, active = false, rounded = false, onClick }) => {
  return (
    <HeartIconWrapper className={className} onClick={onClick} active={active} rounded={rounded}>
      <FavoriteBorderIcon />
    </HeartIconWrapper>
  )
}
