import { Skeleton, useMediaQuery } from '@mui/material'
import { Box } from '@mui/system'
import { FC } from 'react'

const FavoritesSkeleton: FC = () => {
  const isSmallScreen = useMediaQuery('(max-width:600px)')
  return (
    <Box sx={{ padding: isSmallScreen ? '3rem 1.5rem' : '3rem 2rem' }} data-testid={'favorites-skeleton-wrapper'}>
      <Skeleton variant="rectangular" height={'35px'} width={'150px'} />
      <Box
        display={'flex'}
        sx={{
          gap: 1,
        }}
        mt={3}
        pb={3}
      >
        <Skeleton variant="rounded" width={70} height={'38px'} />
        <Skeleton variant="rounded" width={70} height={'38px'} />
        <Skeleton variant="rounded" width={70} height={'38px'} />
      </Box>
      <Box
        display={'flex'}
        sx={{
          gap: 1,
        }}
        pb={3}
      >
        <Skeleton variant="rectangular" width={70} height={'35px'} />
        <Skeleton variant="rounded" width={38} height={'38px'} />
        <Skeleton variant="rounded" width={70} height={'38px'} style={{ marginLeft: 'auto' }} />
      </Box>
      <Box mb={1}>
        <Skeleton variant="rectangular" height={100} />
      </Box>
      <Box mb={1}>
        <Skeleton variant="rectangular" height={100} />
      </Box>
      <Box mb={1}>
        <Skeleton variant="rectangular" height={100} />
      </Box>
    </Box>
  )
}
export default FavoritesSkeleton
