export const timeFormatter = (time: string): string => {
  const [hours, minutes, seconds] = time.split(':').map(Number)

  if (!isNaN(hours) && !isNaN(minutes) && !isNaN(seconds)) {
    let period = 'AM'
    let formattedHours = hours

    if (hours >= 12) {
      period = 'PM'
      formattedHours = hours === 12 ? 12 : hours - 12
    }

    if (formattedHours === 0) {
      formattedHours = 12
    }

    return `${formattedHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`
  }

  return '-'
}
export const priceFormatter = (price: string | null | undefined): string => {
  if (!price) {
    return 'Price Unknown'
  }

  const parsedPrice = parseFloat(price)
  if (isNaN(parsedPrice)) {
    return 'Price Unknown'
  }

  if (parsedPrice === 0) {
    return 'Free'
  }

  return `$${parsedPrice.toFixed(0)} /day`
}

export const dateToHumanReadable = (dateString: string): string => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const [year, month, day] = dateString.split('-').map(Number)

  if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
    const monthName = months[month - 1]
    return `${monthName} ${day.toString().padStart(2, '0')}, ${year}`
  }

  return '-'
}

export const nextHour = (timespan: string | undefined | null): string => {
  if (!timespan) {
    return ''
  }
  const [hours, minutes] = timespan.split(':').map(Number)
  const nextHour = (hours + 1) % 24
  return `${String(nextHour).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
}

export const getFirstDate = (dates: string[]): string => {
  if (!dates.length) {
    return ''
  }
  return [...dates].sort()[0]
}
export const getLastDate = (dates: string[]): string => {
  if (!dates.length) {
    return ''
  }
  if (dates.length < 1) {
    return ''
  }
  return [...dates].sort()[dates.length - 1]
}
