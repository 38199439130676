import EventBus from '@libs/eventbus'
import {
  setPricePerDayIncludeUnknown,
  setPricePerDayRange,
  setPricePerDayScholarshipsOnly,
} from '@store/campFilters/campFiltersSlice.ts'
import { PRICE_PER_DAY_RANGE } from '@store/campSearch/campSearchSaga.ts'
import { useAppDispatch } from '@store/hooks'
import mixpanel from 'mixpanel-browser'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export const usePricePerDayFilters = (): void => {
  const dispatch = useAppDispatch()

  const [searchParams] = useSearchParams()

  const filter_price_per_day = searchParams.get('filter_price_per_day')
  const filter_scholarships_only = searchParams.get('filter_scholarships_only')
  const filter_include_unknown = searchParams.get('filter_include_unknown')

  useEffect(() => {
    if (filter_price_per_day) {
      try {
        const jsonString = filter_price_per_day.replace(/'([^']*)'/g, '"$1"')
        const filterPricePerDayRange = JSON.parse(jsonString) || []
        mixpanel.track(EventBus.MIXPANEL_FILTER_URL, {
          Range: filterPricePerDayRange,
        })
        if (
          filterPricePerDayRange[1] > PRICE_PER_DAY_RANGE.TOP_LIMIT ||
          filterPricePerDayRange[1] === null ||
          filterPricePerDayRange[1] <= filterPricePerDayRange[0] ||
          filterPricePerDayRange[1] <= PRICE_PER_DAY_RANGE.MIN ||
          typeof filterPricePerDayRange[1] === 'string'
        ) {
          filterPricePerDayRange[1] = PRICE_PER_DAY_RANGE.MAX
        }
        if (
          filterPricePerDayRange[0] < PRICE_PER_DAY_RANGE.MIN ||
          filterPricePerDayRange[0] === null ||
          typeof filterPricePerDayRange[0] === 'string' ||
          filterPricePerDayRange[0] >= PRICE_PER_DAY_RANGE.TOP_LIMIT ||
          filterPricePerDayRange[0] >= filterPricePerDayRange[1]
        ) {
          filterPricePerDayRange[0] = PRICE_PER_DAY_RANGE.MIN
        }
        if (Array.isArray(filterPricePerDayRange)) {
          dispatch(setPricePerDayRange(filterPricePerDayRange))
        }
      } catch (e) {
        console.warn('tried to set price range but failed')
      }
      const params = new URLSearchParams(searchParams)
      params.delete('filter_price_per_day')
      const url = params.toString() ? `/camps?${params.toString()}` : `/camps`
      window.history.replaceState(null, '', url)
    }
  }, [filter_price_per_day])

  useEffect(() => {
    if (filter_scholarships_only) {
      dispatch(setPricePerDayScholarshipsOnly(filter_scholarships_only === 'true'))
      const params = new URLSearchParams(searchParams)
      params.delete('filter_scholarships_only')
      const url = params.toString() ? `/camps?${params.toString()}` : `/camps`
      window.history.replaceState(null, '', url)
    }
  }, [filter_scholarships_only])

  useEffect(() => {
    if (filter_include_unknown) {
      // Check if the value is exactly 'true' or 'false'
      if (filter_include_unknown === 'true' || filter_include_unknown === 'false') {
        dispatch(setPricePerDayIncludeUnknown(filter_include_unknown === 'true'))
      }
      // Remove the parameter regardless of its value
      const params = new URLSearchParams(searchParams)
      params.delete('filter_include_unknown')
      const url = params.toString() ? `/camps?${params.toString()}` : `/camps`
      window.history.replaceState(null, '', url)
    }
  }, [filter_include_unknown])
}
