import moment from 'moment'

const CampPageCalendar = (props: { dates: string[] }): JSX.Element => {
  const dates = props.dates
  const m = dates.map((o: string) => moment(o, 'YYYY-MM-DD'))

  const start = m[0]
  let end: moment.Moment = m[0]
  if (m.length > 1) {
    end = m[m.length - 1]
  }

  if (!end) {
    return <></>
  }

  const calendar = []
  const days = m.map((d: moment.Moment) => d.format('dd').toUpperCase())
  const weeks = moment.duration(end.diff(start)).weeks() + 1
  for (let i = 0; i < weeks; i++) {
    calendar.push(
      <div className="calendar">
        {['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'].map((dayOfTheWeek, ii) => {
          const index = days.indexOf(dayOfTheWeek)
          if (index > -1) {
            days.splice(index, 1)
          }
          return (
            <span className={index > -1 ? '' : 'gray'} key={i + ii}>
              {dayOfTheWeek}
            </span>
          )
        })}
      </div>
    )
  }

  return (
    <>
      {calendar.map((o, i) => (
        <span key={i}>{o}</span>
      ))}
    </>
  )
}

export default CampPageCalendar
