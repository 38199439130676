import Eventbus from '@libs/eventbus'
import { SlotComponentProps } from '@mui/base'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { SliderComponentsPropsOverrides, SliderOwnerState } from '@mui/material/Slider/Slider'
import { setSort } from '@store/campFilters/campFiltersSlice'
import { useAppDispatch, useAppSelector } from '@store/hooks.ts'

interface SortProps {
  fullWidth?: boolean
}

export default function Sort({ fullWidth = false }: SortProps): JSX.Element {
  const dispatch = useAppDispatch()
  const sortBy = useAppSelector((state) => state.campFilters?.sort?.by || 'starting-date')

  const handleSortChange = (event: SelectChangeEvent) => {
    dispatch(setSort(event.target.value))
    Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
      filter: 'Sort',
      value: event.target.value,
    })
    Eventbus.trigger(Eventbus.RESET_PAGING)
  }

  if (!sortBy) {
    return <></>
  }

  return (
    <FormControl fullWidth={fullWidth}>
      <Select
        slotProps={{
          input: {
            'data-testid': 'sort-select',
          } as SlotComponentProps<'input', SliderComponentsPropsOverrides, SliderOwnerState>,
        }}
        size="small"
        labelId="sort-select-label"
        id="sort-select"
        value={sortBy}
        onChange={handleSortChange}
      >
        <MenuItem value={'newest'}>Newest</MenuItem>
        <MenuItem value={'starting-date'}>Starting date</MenuItem>
        <MenuItem value={'registration-date'}>Registration Date</MenuItem>
        <MenuItem value={'daily-price-low'}>Daily Price (Low to High)</MenuItem>
        <MenuItem value={'daily-price-high'}>Daily Price (High to Low)</MenuItem>
      </Select>
    </FormControl>
  )
}
