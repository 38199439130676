import { Button } from '@stories/Theme/Button/Button'
import { Logo } from '@stories/Theme/Logo/Logo'
import { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'

import Ellipse from '../components/Ellipse'
import { EllipseEnum } from '../libs/types/Ellipse'
import { AuthLayoutWrapper, HeaderWrapper } from './style'

const AuthLayout: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <>
      <AuthLayoutWrapper>
        <HeaderWrapper>
          <div className="nav-container">
            <div className="left-part">
              <Logo />
            </div>
            <div className="right-part-desktop">
              <Link to="/login">
                <Button title={'Login'} size="small" variant={'secondary'} mr={'0.75rem'} />
              </Link>
              <Link to="/register">
                <Button title={'Sign up'} size="small" variant={'primary'} />
              </Link>
            </div>
          </div>
          <Ellipse type={EllipseEnum.default} />
        </HeaderWrapper>
        <div className="auth-content">{children}</div>
      </AuthLayoutWrapper>
    </>
  )
}

export default AuthLayout
