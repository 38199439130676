import Eventbus from '@libs/eventbus'
import { SlotComponentProps } from '@mui/base'
import { Box, FormControlLabel, Slider, Switch, Typography } from '@mui/material'
import { SliderComponentsPropsOverrides, SliderOwnerState } from '@mui/material/Slider/Slider'
import {
  setPricePerDayIncludeUnknown,
  setPricePerDayRange,
  setPricePerDayScholarshipsOnly,
} from '@store/campFilters/campFiltersSlice'
import { PRICE_PER_DAY_RANGE } from '@store/campSearch/campSearchSaga.ts'
import { useAppDispatch, useAppSelector } from '@store/hooks.ts'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const step = 10

const priceSeries: { value: number; label?: string }[] = Array.from(
  { length: PRICE_PER_DAY_RANGE.TOP_LIMIT / step + 1 },
  (_, index) => ({
    value: index * step,
  })
)

priceSeries.push({ value: PRICE_PER_DAY_RANGE.TOP_LIMIT + 30, label: 'Any' })

function valuetext(value: number) {
  if (value > PRICE_PER_DAY_RANGE.TOP_LIMIT) {
    return 'Any'
  }
  return `$${value} `
}

const MyLabelTypography = styled(Typography)`
  position: relative;
  top: -24px;
  font-size: 14px !important;
  display: flex;
  justify-content: flex-end;
  padding: 0 8%;
`

const PricePerDay = (): JSX.Element => {
  const dispatch = useAppDispatch()

  const value = useAppSelector((state) => state.campFilters.filters.pricePerDay.range)
  const scholarshipsOnly = useAppSelector((state) => state.campFilters.filters.pricePerDay.scholarshipsOnly)
  const includeUnknown = useAppSelector((state) => state.campFilters.filters.pricePerDay.includeUnknown)

  const [tempValue, setTempValue] = useState<number[]>(value)

  useEffect(() => {
    setTempValue(value)
  }, [value])

  const handleChange = (_event: Event, newTempValue: number | number[]) => {
    setTempValue(newTempValue as number[])
    Eventbus.trigger(Eventbus.RESET_PAGING)
  }

  const handlePricePerDayRangeChange = (_event: React.SyntheticEvent | Event, newValue: number | number[]) => {
    if (!Array.isArray(newValue)) {
      return
    }

    if (newValue[1] > PRICE_PER_DAY_RANGE.TOP_LIMIT) {
      newValue[1] = PRICE_PER_DAY_RANGE.MAX
    }

    dispatch(setPricePerDayRange(newValue))
    Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
      filter: 'Price Per Day Range',
      value: newValue,
    })
  }

  function handleScholarshipsOnlyChange(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch(setPricePerDayScholarshipsOnly(event.target.checked))
    Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
      filter: 'Scholarships Only',
      value: event.target.checked,
    })
    Eventbus.trigger(Eventbus.RESET_PAGING)
  }

  function handleIncludeUnknownChange(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch(setPricePerDayIncludeUnknown(event.target.checked))
    Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
      filter: 'Include Unknown',
      value: event.target.checked,
    })
    Eventbus.trigger(Eventbus.RESET_PAGING)
  }

  return (
    <>
      <Box px={3} pt={4}>
        <Slider
          slotProps={{
            input: {
              'data-testid': 'slider-price',
            } as SlotComponentProps<'input', SliderComponentsPropsOverrides, SliderOwnerState>,
          }}
          disableSwap
          getAriaLabel={() => 'Price per day'}
          marks={priceSeries}
          max={PRICE_PER_DAY_RANGE.TOP_LIMIT + 30}
          min={PRICE_PER_DAY_RANGE.MIN}
          step={null}
          onChange={handleChange}
          onChangeCommitted={handlePricePerDayRangeChange}
          value={tempValue}
          valueLabelDisplay="on"
          valueLabelFormat={valuetext}
          sx={{
            '& .MuiSlider-mark': {
              '&[data-index="1"]': {
                display: 'none !important',
              },
              '&[data-index="17"]': {
                display: 'none !important',
              },
              '&[data-index="35"]': {
                // Correctly targeting the mark with data-index="17"
                display: 'block !important',
                height: '20px',
                background: '#98A2B3',
              },
            },
          }}
        />
        <MyLabelTypography>
          <span>{valuetext(PRICE_PER_DAY_RANGE.TOP_LIMIT)}</span>
        </MyLabelTypography>
      </Box>
      <FormControlLabel
        control={<Switch onChange={handleScholarshipsOnlyChange} sx={{ m: 1 }} checked={scholarshipsOnly} />}
        label="Show only camps that provide scholarships"
        data-testid="scholarships"
      />
      <FormControlLabel
        control={<Switch onChange={handleIncludeUnknownChange} sx={{ m: 1 }} checked={includeUnknown} />}
        label="Include Unknown"
      />
    </>
  )
}

export default PricePerDay
